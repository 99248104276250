<template>
    <div id="apipe-cloud-course-page">
      <top-nav-new />
      <div class="apipe-cloud-course-container-madeSolution">
        <div class="pc-banner d-none d-lg-block">
          <div class="container" style="text-align: left">
            <h1 style="margin-bottom: 15px; font-size: 42px; font-weight: 700">
                优制云财务管理FIMS
            </h1>
            <p class="tit">
                   优制云FIMS财务管理系统，专为中小微企业量身打造，直击财务管理繁琐、资金流转不透明的<br>痛点。集成智能记账、财务分析、成本控制等功能，一键生成报表，让财务数据一目了然。实时<br>监控现金流，优化资金运作，助力企业精准决策。简洁易用，无需专业财务知识，轻松管理企业<br>财务，加速企业发展步伐。
            </p>
          </div>
          <MessageInfo />
        </div>
  
        <div class="mobile-banner d-block d-lg-none">
          <div class="container" style="text-align: left">
            <div class="title">优制云财务管理FIMS</div>
            <div class="titwrap">
              <p class="tit">
                通过提供资产管理、预算控制、财务报告、成本分析等功能，帮助企业优化资金运作，降低财务风险，实现高效财务管理。
              </p>              
            </div>

          </div>
        </div>
  
        <!-- 行业痛点 -->
        <div class="pc-industry d-none d-lg-block">
          <div class="pro-tit">行业痛点</div>
          <div class="container text-center">
            <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/fims/hangye1.png" alt="" />
                  <h6>财务数据不准确</h6>
                  <div class="des">
                    <div>手工记账容易出错，数据准确性</div>
                    <div>低</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/fims/hangye2.png" alt="" />
                  <h6>预算控制困难</h6>
                  <div class="des">
                    <div>预算执行情况难以跟踪，超支现</div>
                    <div>象频发</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/fims/hangye3.png" alt="" />
                  <h6> 财务报告不及时</h6>
                  <div class="des">
                    <div>财务报告编制周期长，影响决策</div>
                    <div>速度</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/fims/hangye4.png" alt="" />
                  <h6>税务风险管理高</h6>
                  <div class="des">
                    <div>税务信息处理不当，增加税务风</div>
                    <div>险</div>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="row"></div>
          </div>
        </div>
        <div class="mobile-industry d-block d-lg-none">
          <div class="pro-tit">行业痛点</div>
          <div class="container text-center">
            <div class="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/isc/hangye1.png" alt="" />
                  <h6>财务数据不准确</h6>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/isc/hangye2.png" alt="" />
                  <h6>预算控制困难</h6>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/isc/hangye3.png" alt="" />
                  <h6>财务报告不及时</h6>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/isc/hangye4.png" alt="" />
                  <h6>税务风险管理高</h6>
                </div>
              </div>
            </div>
  
            <div class="row"></div>
          </div>
        </div>
  
        <!-- 解决方案 -->
        <div class="container text-center">
          <div class="pro-tit d-none d-lg-block" style="font-size: 36px">
            解决方案
          </div>
          <div
            class="pro-tit d-block d-lg-none"
            style="font-size: 20px; font-weight: 700; margin-bottom: 20px"
          >
            解决方案
          </div>
          <img
            style="width: 100%"
            src="@/assets/newSite/fims/solution.png"
            alt=""
          />
        </div>
  
        <!-- 产品能力 -->
        <div class="pc-pro-power d-none d-lg-block">
          <div class="pro-tit" style="font-size: 36px">产品能力</div>
          <div>
            <div class="tabs container text-center">
              <div
                :class="['items', tbx == index ? 'active' : '']"
                v-for="(item, index) in tabListNew"
                :key="index"
                @click="handleTab(index)"
              >
                <img :src="item.img1" />
                <div class="tit">{{ item.tit }}</div>
              </div>
            </div>
            <div class="tab-cot">
              <div class="container text-center">
                <div class="row" v-show="tbx == 0">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        应收单，其他应收单，收款单，应收核销
                    </div>
                    <div class="mcot">
                      <div>
                        应收款管理日常业务处理流程，并与供应链销售管理及销售出库，发票等构建完整应收管理全流程闭环
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/fims/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 1">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">应付单，其他应付单，付款单，应付核销</div>
                    <div class="mcot">
                      <div>
                        应付款管理日常业务处理流程，并与供应链采购管理及采购入库，发票等构建完整应付管理全流程闭环
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/fims/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 2">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">资产台账，工作量，计提折旧，调整单，盘点单等</div>
                    <div class="mcot">
                      <div>
                        固定资产以资产台账管理为中心，从资产购入企业开始到资产报废处理的完整生命周期的管理；能针对资产实物进行使用，计提折旧，费用分摊等全流程跟踪记录；实现资产管理工作的信息化、规范化与标准化管理使资产的管理变得轻松、准确，快捷。
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/fims/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 3">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit"> 借款审批单，费用报销单，付款申请单，差旅费用报销单，领款单
                    </div>
                    <div class="mcot">
                      <div>
                        面向企业员工日程报销管理系统，集成OA审批，资金出纳，应付账款等业务构建完整业务流程闭环，协助企业费用合理统筹，防止资金不必要的浪费及支出
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/fims/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 4">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        存货核算，产品成本核算，标准成本分析
                    </div>
                    <div class="mcot">
                      <div>
                        将高内聚低耦合的供应链销售，采购、生产制造、进销存，应收应付、资产管理、总账等模块的业务数据互联互通，结合标准财务成本算法规则，提供企业准确的成本数据做依据
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/fims/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 5">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        凭证管理，资金管理，财务报表
                    </div>
                    <div class="mcot">
                      <div>
                        以凭证管理为记账核心进行账务精细化管控，与应收，应付，费用，固定资产，成本核算等模块业务数据互联互通，环环相扣，实现财务数据统一共享，经纬分明
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/fims/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
 
              </div>
            </div>
          </div>
        </div>
  
        <div class="mobile-pro-power d-block d-lg-none">
          <h4 class="pro-tit">产品能力</h4>
          <div class="">
            <div class="tabs">
              <div
                :class="['items', tbx == index ? 'active' : '']"
                v-for="(item, index) in tabListNew"
                :key="index"
                @click="handleTab(index)"
              >
                <img :src="item.img1" />
                <div class="tit">{{ item.tit }}</div>
              </div>
            </div>
            <div class="tab-cot">
              <div class="container text-center">
                <div class="row" v-show="tbx == 0">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                    <h4 style="text-align: left">应收单，其他应收单，收款单，应收核销</h4>
                    <div
                      style="
                        text-align: left;
                        margin: 10px 0 10px 0;
                        color: #333333;
                      "
                    >
                      <div>应收款管理日常业务处理流程，并与供应链销售管理及销售出库，发票等构建完整应收管理全流程闭环</div>
                    </div>
                  </div>
                  <div
                  >
                    <img
                      src="@/assets/newSite/fims/c.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>
  
                <div class="row" v-show="tbx == 1">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                    <h4 style="text-align: left">应付单，其他应付单，付款单，应付核销</h4>
                    <div
                      style="
                        text-align: left;
                        margin: 10px 0 10px 0;
                        color: #333333;
                      "
                    >
                      <div>应付款管理日常业务处理流程，并与供应链采购管理及采购入库，发票等构建完整应付管理全流程闭环</div>
                    </div>
                  </div>
                  <div
                  >
                    <img
                      src="@/assets/newSite/fims/c.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 2">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                    <h4 style="text-align: left">资产台账，工作量，计提折旧，调整单，盘点单等</h4>
                    <div
                      style="
                        text-align: left;
                        margin: 10px 0 10px 0;
                        color: #333333;
                      "
                    >
                      <div>固定资产以资产台账管理为中心，从资产购入企业开始到资产报废处理的完整生命周期的管理；能针对资产实物进行使用，计提折旧，费用分摊等全流程跟踪记录；实现资产管理工作的信息化、规范化与标准化管理使资产的管理变得轻松、准确，快捷。</div>
                    </div>
                  </div>
                  <div
                  >
                    <img
                      src="@/assets/newSite/fims/c.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 3">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                    <h4 style="text-align: left">借款审批单，费用报销单，付款申请单，差旅费用报销单，领款单</h4>
                    <div
                      style="
                        text-align: left;
                        margin: 10px 0 10px 0;
                        color: #333333;
                      "
                    >
                      <div>面向企业员工日程报销管理系统，集成OA审批，资金出纳，应付账款等业务构建完整业务流程闭环，协助企业费用合理统筹，防止资金不必要的浪费及支出</div>
                    </div>
                  </div>
                  <div
                  >
                    <img
                      src="@/assets/newSite/fims/c.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 4">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                    <h4 style="text-align: left">存货核算，产品成本核算，标准成本分析</h4>
                    <div
                      style="
                        text-align: left;
                        margin: 10px 0 10px 0;
                        color: #333333;
                      "
                    >
                      <div>将高内聚低耦合的供应链销售，采购、生产制造、进销存，应收应付、资产管理、总账等模块的业务数据互联互通，结合标准财务成本算法规则，提供企业准确的成本数据做依据</div>
                    </div>
                  </div>
                  <div
                  >
                    <img
                      src="@/assets/newSite/fims/c.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 5">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                    <h4 style="text-align: left">凭证管理，资金管理，财务报表</h4>
                    <div
                      style="
                        text-align: left;
                        margin: 10px 0 10px 0;
                        color: #333333;
                      "
                    >
                      <div>以凭证管理为记账核心进行账务精细化管控，与应收，应付，费用，固定资产，成本核算等模块业务数据互联互通，环环相扣，实现财务数据统一共享，经纬分明</div>
                    </div>
                  </div>
                  <div
                  >
                    <img
                      src="@/assets/newSite/fims/c.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
  
        <!-- 产品优势 -->
        <div class="pc-scene d-none d-lg-block">
          <div class="pro-tit d-none d-lg-block">产品优势</div>
          <div class="container text-center">
            <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/fims/ad1.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/fims/ad2.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/fims/ad3.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/fims/ad4.png" alt="" />
                </div>
              </div>
            </div>
  
            <div class="row"></div>
          </div>
        </div>
        <!-- 产品优势 -->
        <div class="mobile-scene d-block d-lg-none">
          <div
            class="pro-tit d-block d-lg-none"
            style="font-size: 20px; font-weight: 700; margin-bottom: 20px"
          >
            产品优势
          </div>
          <div class="container text-center">
            <div class="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/fims/ad1.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/fims/ad2.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/fims/ad3.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/fims/ad4.png" alt="" />
                </div>
              </div>
            </div>
  
            <div class="row"></div>
          </div>
        </div>
  
        <!-- 成功案列 -->
        <section
          class="pc-product d-none d-lg-block"
          style="margin: 80px 0 50px 0"
        >
          <div class="container text-center">
            <h1 class="pro-tit" style="margin-bottom: 20px; font-size: 36px">
              成功案例
            </h1>
            <div class="row align-items-start">
              <div
                :class="['col', idx == index ? 'acticv' : '']"
                @click="handleSwitch(index)"
                v-for="(item, index) in tabs"
                :key="index"
              >
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
          <div class="pro-wrap">
            <div
              class="container animate__animated animate__fadeInLeftBig"
              v-show="idx == 0"
            >
              <div class="content" style="display: flex;padding:20px 0 ;">
                <div class="wrap">
                  <h6 style="font-size: 35px">
                    {{caseA.contentTitle}}
                  </h6>
                  <p class="p-content">
                    {{caseA.contentDescription}}
                  </p>
                  <div>
                    <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseA.id}&tit=${caseA.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm " 
                >
                  查看详情
                </el-button>
                  </div>
                </div>
                <div style="flex: 40%; text-align: center">
                  <img :src="baseImgUrl + caseA.contentImgUrl" width="640px" height="360px"/>
                </div>
              </div>
            </div>
            <div
              class="container animate__animated animate__fadeInLeftBig"
              v-show="idx == 1"
            >
              <div class="content" style="display: flex;padding:20px 0 ;">
                <div class="wrap"  style="flex: 60%">
                  <h6 style="font-size:35px;">{{caseB.contentTitle}}</h6>
                  <p class="p-content">
                    {{caseB.contentDescription}}
                  </p>
                  <div>
                    <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseB.id}&tit=${caseB.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm " 
                >
                  查看详情
                </el-button>
                  </div>
                </div>
                <div style="flex: 40%; text-align: center">
                  <img :src="baseImgUrl + caseB.contentImgUrl" width="640px" height="360px"/>
                </div>
              </div>
            </div>
            <div
              class="container animate__animated animate__fadeInRightBig"
              v-show="idx == 2"
            >
              <div class="content" style="display: flex;padding:20px 0 ;">
                <div class="wrap" style="flex: 60%">
                  <h6  style="font-size:35px;">{{caseC.contentTitle}}</h6>
                  <p class="p-content">
                    {{caseC.contentDescription}}
                  </p>
                  <div>
                    <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseC.id}&tit=${caseC.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm" 
                >
                  查看详情
                </el-button>
                  </div>
                </div>
                <div style="flex: 40%; text-align: center">
                  <img :src="baseImgUrl + caseC.contentImgUrl" width="640px" height="360px"/>
                </div>
              </div>
            </div>
          </div>
        </section>
  
        <section
          class="mobile-product d-block d-lg-none"
          style="margin-top: 40px"
        >
          <div class="container text-center">
            <h3 class="pro-tit">成功案例</h3>
            <div class="row align-items-start">
              <div
                :class="['col', idx == index ? 'acticv' : '']"
                @click="handleSwitch(index)"
                v-for="(item, index) in tabs"
                :key="index"
              >
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
          <div class="pro-wrap">
            <div
              class="container animate__animated animate__fadeInLeftBig"
              v-show="idx == 0"
            >
              <div class="content">
                <div style="flex: 60%">
                <h6 style="font-weight: 700; margin-bottom: 1rem">
                  {{caseA.contentTitle}}
                </h6>
                <p class="p-content">
                  {{caseA.contentDescription}}
                </p>
              </div>
              <div style="text-align: center; margin-bottom: 20px">
                <img :src="baseImgUrl + caseA.contentImgUrl"/>
              </div>
              <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseA.id}&tit=${caseA.contentTitle}`);
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
              </div>
            </div>
            <div
              class="container animate__animated animate__fadeInLeftBig"
              v-show="idx == 1"
            >
              <div class="content">
                <div style="flex: 60%">
                <h6 style="font-weight: 700; margin-bottom: 1rem">
                  {{caseB.contentTitle}}
                </h6>
                <p class="p-content">
                  {{caseB.contentDescription}}
                </p>
              </div>
              <div style="text-align: center; margin-bottom: 20px">
                <img :src="baseImgUrl + caseB.contentImgUrl"/>
              </div>
              <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseB.id}&tit=${caseB.contentTitle}`);
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
              </div>
            </div>
            <div
              class="container animate__animated animate__fadeInRightBig"
              v-show="idx == 2"
            >
              <div class="content">
                <div style="flex: 60%">
                <h6 style="font-weight: 700; margin-bottom: 1rem">
                  {{caseC.contentTitle}}
                </h6>
                <p class="p-content">
                  {{caseC.contentDescription}}
                </p>
              </div>
              <div style="text-align: center; margin-bottom: 20px">
                <img :src="baseImgUrl + caseC.contentImgUrl"/>
              </div>
              <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseC.id}&tit=${caseC.contentTitle}`);
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
              </div>
            </div>
          </div>
        </section>
  
        <right-nav-new class="d-none d-lg-block" />
      </div>
  
      <NewFooter @handleClickChild="handleClickChild" />
      <MobileFooter ref="mofooter" class="d-block d-lg-none" />
    </div>
  </template>
            
    <script>
  import { Swiper, SwiperSlide } from "vue-awesome-swiper";
  import SwiperCore, { Autoplay } from "swiper";
  SwiperCore.use([Autoplay]);
  import Qs from "qs";
import { Newslist } from "@/api/fetch";
  export default {
    name: "hardware",
    metaInfo() {
    return {
      title: '优制云FIMS系统 - 精准高效的企业财务管理平台',
      meta: [
        { name: 'keywords', content: '财务管理系统,FIMS系统,企业财务管理,财务报表分析,预算管理,工业互联网,SaaS软件,优制云,伟班平台' },
        { name: 'description', content: '优制云FIMS财务管理系统，集成智能记账、财务分析、成本控制等功能，一键生成报表，让财务数据一目了然。实时监控现金流，优化资金运作，助力企业精准决策。' },
        
      ],
    };
  },
    data() {
      return {
        tabs: ["中海源金融", "数研时空", "润玉空间"],
        activeName: "1",
        idx: 0,
        tbx: 0,
        ids: -1,
        tabListNew: [
          {
            img1: require("@/assets/newSite/fims/p1.png"),
            tit: "应收管理",
          },
          {
            img1: require("@/assets/newSite/fims/p2.png"),
            tit: "应付管理",
          },
          {
            img1: require("@/assets/newSite/fims/p3.png"),
            tit: "固定资产",
          },
          {
            img1: require("@/assets/newSite/fims/p4.png"),
            tit: "费用管理",
          },
          {
            img1: require("@/assets/newSite/fims/p5.png"),
            tit: "成本核算",
          },
          {
            img1: require("@/assets/newSite/fims/p6.png"),
            tit: "总账",
          }
        ],
  
        swiperOption: {
          slidesPerView: 3,
          spaceBetween: 30,
          freeMode: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        },
        baseImgUrl:'https://admin.veiban.com',
      caseList:[],
      caseA:{},
      caseB:{},
      caseC:{},
      };
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    created() {
    this.getCase();
  },
    methods: {
      //成功案例
      handleClickChild(){
     this.$refs.mofooter.handleDeal(2)
    },
    getCase() {
      
      Newslist(
        Qs.stringify({ categoryId: '1839482945283117058', pageSize: 10, pageNo: 1,productCode:'fims' })
      ).then((res) => {
        if (res.data.rows.length) {
            this.caseA = res.data.rows [0]
            this.caseB = res.data.rows [1]
            this.caseC = res.data.rows [2]
            this.caseList =  res.data.rows
        }
      });
    },
    openNewWindow(path) {
      const routeData = this.$router.resolve({path,id:'123456'});
      window.open(routeData.href, "_blank");
    },
      handleSwitch(idx) {
        this.idx = idx;
      },
      handleTest() {
        window.open("https://apipecloud.veiban.com/login");
      },
      handleClick(tab, event) {
        console.log(tab, event);
      },
  
      handleTab(index) {
        this.tbx = index;
      },
    },
    destroyed() {
      window.removeEventListener("scroll", this.onScroll, false);
    },
  };
  </script>
            
    <style lang="less" scoped>
  .apipe-cloud-course-container-madeSolution {
    .pc-banner {
      height: 600px;
      background-image: url("../../assets/newSite/fims/banner.png");
      background-size: cover;
      background-repeat: no-repeat;
      .container {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        .tit {
          font-size: 18px;
          margin: 4px 0;
        }
        .exp {
          margin-top: 2rem;
          font-size: 14px;
          border: 1px solid white;
          display: inline-block;
          width: 100px;
          padding: 2px 0;
          text-align: center;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .mobile-banner {
      height: 300px;
      width: 100%;
      background-image: url("../../assets/newSite/fims/banner.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      .container {
        color: white;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        .title {
          font-size: 35px;
        text-align: left;
        color: white;
        font-family:Source Han Sans CN;
        font-weight:200;
        margin-bottom: 30px;
        }
        .titwrap{
        padding: 30px 20px;
        .tit {
          font-size: 13px;
          margin: 5px 0;
        }        
      }
        .exp {
          margin-top: 0.5rem;
          font-size: 12px;
          border: 1px solid white;
          display: inline-block;
          width: 80px;
          text-align: center;
        }
      }
    }
    .pc-industry {
      margin: 80px 0;
      .pro-tit {
        margin-bottom: 40px;
        font-size: 36px;
      }
      .col {
        .p-3 {
          background: #f6faff;
          height: 240px;
          width: 300px;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        }
      }
      img {
        width: 100px;
        height: 100px;
      }
      h6 {
        margin-top: 0.5rem;
        font-size: 16px;
        font-weight: 700;
        color: rgba(51, 51, 51, 1);
      }
      .des {
        color: #333333;
        font-size: 14px;
      }
    }
    .mobile-industry {
      margin: 40px 0;
      .pro-tit {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 700;
        color: #333;
      }
      .col {
        .p-3 {
          background: #f6faff;
        margin:10px ;
        }
      }
      img {
        // width: 100px;
        // height: 100px;
      }
      h6 {
        font-family: Source Han Sans CN;
        font-weight: 200;
        color: #333333;
        font-size: 15px;
      }
      .des {
        color: #333333;
        font-size: 14px;
      }
    }
    .pc-pro-power {
      margin: 50px 0;
      .pro-tit {
        margin-bottom: 30px;
      }
      .tabs {
        display: flex;
        .items {
          flex: 25%;
          // height: 120px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          .tit {
            padding: 20px 0 16px 0;
            font-size: 18px;
            font-weight: 700;
          }
          &:hover {
            cursor: pointer;
          }
          img {
            width: 36px;
            height: 36px;
          }
        }
        .active {
          .tit {
            border-bottom: 4px solid rgba(20, 132, 255, 1);
          }
        }
      }
      .tab-cot {
        background: #f6faff;
        padding: 20px;
        .row {
          min-height: 300px;
          padding: 20px 100px;
          .mtit {
            text-align: left;
            font-size: 24px;
            font-weight: 500;
          }
          .mcot {
            text-align: left;
            margin: 20px 0 30px 0;
            color: #333333;
          }
          .lf {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            justify-content: center;
            padding: 10px;
            .btn {
              width: 120px;
              background: #1484ff;
              &:hover {
                background: #66b1ff;
              }
            }
          }
          p {
            color: #666666;
            font-size: 14px;
          }
        }
      }
    }
    .mobile-pro-power {
      margin: 50px 0;
      .pro-tit {
        margin-bottom: 30px;
        font-size: 20px;
        color: #333;
        font-weight: 700;
      }
      .tabs {
        display: flex;
        width: 375px;
        overflow-x: scroll;
        overflow-y: hidden;
        margin: 0 auto;
        .items {
          width: 33%;
          flex-shrink: 0;
          // height: 120px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          &:hover {
            cursor: pointer;
          }
          .tit {
            padding: 20px 0 16px 0;
            font-family: Source Han Sans CN;
            font-weight: 700;
            font-size: 16px;
          }
          img {
            width: 24px;
            height: 24px;
          }
        }
        .active {
          .tit {
            border-bottom: 4px solid rgba(20, 132, 255, 1);
          }
        }
      }
      .tab-cot {
        background: #f6faff;
        padding: 20px 0;
        .row {
          min-height: 300px;
          padding: 10px;
          .lf {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            justify-content: center;
            padding: 10px;
            .btn {
              width: 100px;
            }
          }
          p {
            color: #666666;
            font-size: 14px;
          }
        }
      }
    }
    .pc-scene {
      margin: 80px 0;
      .pro-tit {
        margin-bottom: 30px;
        font-size: 36px;
      }
      .col {
        .p-3 {
          min-height: 200px;
          padding: 0 !important;
        }
      }
      img {
        width: 100%;
      }
      h6 {
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
      }
    }
    .mobile-scene {
      margin: 0 0;
      .pro-tit {
        margin-bottom: 30px;
        font-size: 36px;
      }
      .col {
        .p-3 {
          padding: 5px !important;
        }
      }
      img {
        width: 100%;
      }
      h6 {
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
      }
    }

    .pc-product {
      .acticv {
        span {
          height: 4px;
          border-bottom: 4px solid #1484ff;
          color: #1484ff;
        }
      }
      .col {
        cursor: pointer;
        span {
          display: inline-block;
          height: 40px;
        }
      }
      .pro-wrap {
        background: #f6faff;
        text-align: left;
        .container {
          display: flex;
  
          .cot {
            background: url("../../assets/newSite/home/pc.png");
            background-repeat: no-repeat;
            background-size: cover;
            height: 702px;
            width: 100%;
          }
          img {
            // height: 16rem;
          }
          .content {
            margin: 40px 0;
            width: 100%;
            .wrap {
              flex: 60%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              p {
                font-family: Source Han Sans CN;
                font-weight: 200;
                color: #333333;
                width: 540px;
                margin-bottom: 20px;
                margin-top: 12px;
                font-size: 18px;
              }
            }
            .btn {
              width: 120px;
              background: #1484ff;
              height: 36px;
            }
          }
        }
      }
    }
    .mobile-product {
      .acticv {
        span {
          height: 4px;
          border-bottom: 4px solid #1484ff;
          color: #1484ff;
        }
      }
      .col {
        padding: 10px 0 0  0;
        cursor: pointer;
        span {
          display: inline-block;
          height: 40px;
          font-family: Source Han Sans CN;
          font-weight: 700;
          font-size: 16px;
        }
      }
      .pro-tit {
        font-family: Source Han Sans CN;
        font-weight: 700;
        color: #333333;
        font-size: 20px;
        margin-bottom: 15px;
      }
      .pro-wrap {
        text-align: left;
        background: #f6faff;
        .container {
          display: flex;
          .content {
            padding: 2rem 1.5rem 1.5rem 1.5rem;
            width: 100%;
            img {
              width: 100%;
              // height: 20rem;
            }
            .p-content {
              color: #666;
              font-size: 0.8rem;
              font-family: "思源黑体";
              line-height: 1.6rem;
            }
            .btn {
              width: 120px;
              background: #1484ff;
              color: white;
            }
          }
        }
      }
    }
  
   
  
   
  }
  </style>
            